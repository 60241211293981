$fontBody: 'Esteban', serif;
$fontHeadline: 'Montserrat', sans-serif;

$colorBright: #046865;
$colorDark: #000;
$colorYellow: #ffc000;

$appPaddingTop: 150px;
$appPaddingBottom: 75px;
$appPaddingRight: 120px;

$largeImageSize: 38vw;
$frontpageHeadlineSize: 5.7vw;
$subpageWidth: 65vw;

//mobile
$largeImageSizeMobile: 79vw;
$appPaddingMobile: 25px;
$appPaddingMobileTop: 29px;
$frontpageHeadlineSizeMobile: 7.1vw;
$subpageWidthMobile: 100%;
