/** resets */
body {
	-webkit-font-smoothing: antialiased;
}

h1, h2, h3,h4, h5, h6,p {margin: 0;padding: 0;}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

div {
	position: relative;
}

a {color: inherit;text-decoration: none;}

a,a:focus, a:active,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

hr {
	margin: 0;
	padding: 0;
	border: 0;
	border-top: 1px solid #000;
}
