@import url(https://fonts.googleapis.com/css?family=Esteban|Montserrat:700,500);
/*
Theme Name: Marie-Louise Performance
Author: Troels Knud Damgaard
*/
/* SETUP */
/* VARIABLES */
/* RESET */
/** resets */
body {
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

div {
  position: relative; }

a {
  color: inherit;
  text-decoration: none; }

a, a:focus, a:active,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #000; }

/* General */
*, body, html {
  box-sizing: border-box; }

body, html {
  font-family: "Esteban", serif;
  font-weight: 500;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #fff; }

#root {
  overflow: hidden;
  height: 100%;
  width: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  line-height: 1;
  width: 100%;
  float: left;
  color: #000; }

p, ul {
  font-size: 16px;
  letter-spacing: 0.08em;
  line-height: 1.5em;
  width: 100%;
  float: left;
  margin-bottom: 20px; }

h2 {
  color: #000;
  margin-bottom: 50px;
  font-size: 40px; }
  @media screen and (max-width: 900px) {
    h2 {
      font-size: 30px; } }
  @media screen and (max-width: 700px) {
    h2 {
      font-size: 25px; } }

h3 {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 40px; }
  h3:first-of-type {
    margin-top: 0; }

/* mobile */
.show-on-mobile {
  display: none; }

@media screen and (max-width: 700px) {
  .hide-on-mobile {
    display: none; } }

/* FRAMEWORK */
/* MAIN PARTS */
.frontpage {
  position: absolute;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding-top: 150px;
  padding-bottom: 75px;
  padding-right: 120px;
  right: 0; }
  @media screen and (max-width: 700px) {
    .frontpage {
      padding-right: 25px;
      padding-top: 29px;
      width: 100%; } }
  .frontpage h1, .frontpage h2, .frontpage p, .frontpage .btn {
    text-align: right;
    margin: 0;
    padding: 0; }
  .frontpage h2 {
    font-size: 12px;
    letter-spacing: 0.07em;
    color: #046865;
    margin-bottom: 40px; }
    @media screen and (max-width: 700px) {
      .frontpage h2 {
        font-size: 10px;
        margin-bottom: 25px; } }
  .frontpage h1 {
    font-size: 5.7vw;
    color: #000; }
    @media screen and (max-width: 700px) {
      .frontpage h1 {
        font-size: 7.1vw;
        margin-bottom: 25px; } }
  .frontpage .btn {
    margin-top: 20px; }
    @media screen and (max-width: 700px) {
      .frontpage .btn {
        margin-top: 0; } }
  .frontpage .quote {
    max-width: 450px;
    width: 100%;
    font-size: 18px;
    opacity: 0.8; }
    @media screen and (max-width: 700px) {
      .frontpage .quote {
        font-size: 16px;
        margin-top: 0;
        margin-top: initial;
        width: 220px; } }
    @media screen and (max-width: 360px) {
      .frontpage .quote {
        font-size: 15px;
        width: 200px; } }
    .frontpage .quote:first-of-type {
      margin-top: auto;
      margin-bottom: 30px; }
      @media screen and (max-width: 700px) {
        .frontpage .quote:first-of-type {
          margin-top: 0;
          margin-top: initial; } }
    .frontpage .quote .quote-originator {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.05em; }

.scroll-wrapper {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 65vw; }
  @media screen and (max-width: 700px) {
    .scroll-wrapper {
      width: 100%; } }

.subpage {
  margin-left: 120px;
  margin-top: 150px;
  margin-right: 120px;
  margin-bottom: 80px;
  display: inline-block; }
  @media screen and (max-width: 700px) {
    .subpage {
      width: 100%;
      margin-top: 29px;
      margin-right: 0;
      margin-left: 0;
      padding-left: 25px;
      padding-right: 84px; } }
  .subpage ul {
    float: left;
    margin-top: 0; }
    @media screen and (max-width: 700px) {
      .subpage ul {
        padding-left: 20px; } }
  .subpage .quote {
    font-style: italic; }
    .subpage .quote .quote-originator {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.05em;
      font-style: normal; }

#menubar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 120px; }
  @media screen and (max-width: 700px) {
    #menubar {
      height: 100%; } }

#mainmenu {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  list-style: none;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
  margin: 0;
  padding-right: 134px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  font-weight: 700; }
  @media screen and (max-width: 700px) {
    #mainmenu {
      padding-right: 0;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      font-size: 20px;
      padding-bottom: 25px; } }
  #mainmenu li {
    margin-left: 56px; }
    @media screen and (max-width: 700px) {
      #mainmenu li {
        margin-left: 25px;
        margin-bottom: 10px; } }
    #mainmenu li a {
      float: left;
      position: relative; }
      #mainmenu li a:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #000;
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
        -webkit-transform-origin: center center;
                transform-origin: center center;
        transition: all ease .3s; }
      #mainmenu li a.active:after {
        -webkit-transform: scaleX(1);
                transform: scaleX(1); }

#burger {
  position: absolute;
  top: 60px;
  right: 40px;
  height: 40px;
  width: 40px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 120; }
  @media screen and (max-width: 700px) {
    #burger {
      top: auto;
      top: initial;
      bottom: 25px;
      right: 25px;
      -webkit-transform: none;
              transform: none; } }
  #burger ellipse {
    fill: #ffc000; }
  #burger rect {
    fill: #fff; }

#breadcrumb {
  position: absolute;
  right: 53px;
  top: 150px;
  z-index: 200;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: #046865;
  width: 480px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 700;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: translateX(100%) rotateZ(90deg);
          transform: translateX(100%) rotateZ(90deg); }
  @media screen and (max-width: 700px) {
    #breadcrumb {
      right: 39px;
      font-size: 10px;
      top: auto;
      top: initial;
      bottom: 452px; } }
  @media screen and (max-width: 360px) {
    #breadcrumb {
      font-size: 8px;
      bottom: 374px;
      right: 40px; } }
  #breadcrumb span {
    margin-top: 4px;
    color: #000;
    font-weight: 500;
    color: #000; }

/* APP COMPONENTS */
#largeimage {
  position: fixed;
  bottom: 0; }
  #largeimage img {
    position: absolute;
    bottom: 0;
    left: -3vw;
    width: 38vw; }
    @media screen and (max-width: 700px) {
      #largeimage img {
        width: 79vw;
        left: -24vw; } }

.btn {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #000; }
  .btn a:after {
    content: '>>';
    padding-left: 10px; }

/* scripts */

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

