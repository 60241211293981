.scroll-wrapper {
	position: absolute;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	position: absolute;
	top: 0;
	right: 0;
	height: 100vh;
	width: $subpageWidth;

	@include breakpoint(mobileMedium) {
		width: $subpageWidthMobile;
	}
}

.subpage {
	margin-left: $appPaddingRight;
	margin-top: $appPaddingTop;
	margin-right: $appPaddingRight;
	margin-bottom: 80px;
	display: inline-block;

	@include breakpoint(mobileMedium) {
		width: 100%;
		margin-top: $appPaddingMobileTop;
		margin-right: 0;
		margin-left: 0;
		padding-left: $appPaddingMobile;
		padding-right: 84px;
	}

	ul {
		float: left;
		margin-top: 0;

		@include breakpoint(mobileMedium) {
			padding-left: 20px;
		}
	}

	.quote {
		font-style: italic;

		.quote-originator {
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 0.05em;
			font-style: normal;
		}
	}
}