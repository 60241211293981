@mixin breakpoint($point) {
    @if $point == medium {
        @media screen and (max-width: 1160px) {
            @content;
        }
    }
    @if $point == mobile {
        @media screen and (max-width: 900px) {
            @content;
        }
    }
    @if $point == mobileMedium {
        @media screen and (max-width: 700px) {
            @content;
        }
    }
    @if $point == mobileSmall {
        @media screen and (max-width: 600px) {
            @content;
        }
    }
    @if $point == mobileExtraSmall {
        @media screen and (max-width: 360px) {
            @content;
        }
    }
    @if $point == desktop {
        @media screen and (min-width: 1001px) {
            @content;
        }
    }
}