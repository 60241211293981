/* General */

    *, body, html {
        box-sizing: border-box;
    }

    body, html {
        font-family: $fontBody;
        font-weight: 500;
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background: #fff;
    }

    #root {
        overflow: hidden;
        height: 100%;
        width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: $fontHeadline;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.07em;
        line-height: 1;
        width: 100%;
        float: left;
        color: $colorDark;
    }

    p, ul {
        font-size: 16px;
        letter-spacing: 0.08em;
        line-height: 1.5em;
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    h2 {
        color: $colorDark;
        margin-bottom: 50px;
        font-size: 40px;

        @include breakpoint(mobile) {
            font-size: 30px;
        }

        @include breakpoint(mobileMedium) {
            font-size: 25px;
        }
    }

    h3 {
        font-size: 16px;
        margin-bottom: 20px;
        margin-top: 40px;

        &:first-of-type {
            margin-top: 0;   
        }
    }


/* mobile */
.show-on-mobile {
    display: none;

    @include breakpoint(xsmall) {
        display: block;
    }
}

.hide-on-mobile {
    @include breakpoint(mobileMedium) {
        display: none;
    }
}