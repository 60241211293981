.btn {
	font-family: $fontHeadline;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: 0.1em;
	font-weight: 700;
	color: #000;

	a {
		&:after {
			content: '>>';
			padding-left: 10px;
		}
	}
}