#menubar {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	height: 120px;

	@include breakpoint(mobileMedium) {
		height: 100%;
	}
}

#mainmenu {
	display: flex;
	height: 100%;
	list-style: none;
	justify-content: flex-end;
	align-items: center;
	padding: 0;
	margin: 0;
	padding-right: 134px;
	font-family: $fontHeadline;
	text-transform: uppercase;
	font-size: 12px;
	color: $colorDark;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
	background: #fff;
	font-weight: 700;

	@include breakpoint(mobileMedium) {
		padding-right: 0;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		font-size: 20px;
		padding-bottom: 25px;
	}

	li {
		margin-left: 56px;

		@include breakpoint(mobileMedium) {
			margin-left: 25px;
			margin-bottom: 10px;
		}

		a {
			float: left;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 100%;
				height: 2px;
				background: $colorDark;
				transform: scaleX(0);
				transform-origin: center center;
				transition: all ease .3s;
			}

			&.active {
				&:after {
					transform: scaleX(1);
				}
			}
		}

	}
}

#burger {
	position: absolute;
	top: 60px;
	right: 40px;
	height: 40px;
	width: 40px;
	transform: translateY(-50%);
	z-index: 120;

	@include breakpoint(mobileMedium) {
		top: initial;
		bottom: $appPaddingMobile;
		right: $appPaddingMobile;
		transform: none;
	}

	ellipse {
		fill: $colorYellow;
	}

	rect {
		fill: #fff;
	}
}