#breadcrumb {
	position: absolute;
	right: 53px;
	top: $appPaddingTop;
	z-index: 200;
	font-family: $fontHeadline;
	text-transform: uppercase;
	font-size: 12px;
	color: $colorBright;
	width: 480px;
	display: flex;
	align-items: center;
	font-weight: 700;
	transform-origin: top left;
	transform: translateX(100%) rotateZ(90deg);


	@include breakpoint(mobileMedium) {
		right: 39px;
		font-size: 10px;
		top: initial;
		bottom: 452px;
	}

	@include breakpoint(mobileExtraSmall) {
		font-size: 8px;
		bottom: 374px;
		right: 40px;
	}

	span {
		margin-top: 4px;
		color: #000;
		font-weight: 500;
		color: $colorDark;
	}
}