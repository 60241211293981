#largeimage {
	position: fixed;
	bottom: 0;

	img {
		position: absolute;
		bottom: 0;
		left: -3vw;
		width: $largeImageSize;

		@include breakpoint(mobileMedium) {
			width: $largeImageSizeMobile;
			left: -24vw;
		}
	}
}