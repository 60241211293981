.frontpage {
	position: absolute;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-top: $appPaddingTop;
	padding-bottom: $appPaddingBottom;
	padding-right: $appPaddingRight;
	right: 0;

	@include breakpoint(mobileMedium) {
		padding-right: $appPaddingMobile;
		padding-top: $appPaddingMobileTop;
		width: 100%;
	}

	h1, h2, p, .btn {
		text-align: right;
		margin: 0;
		padding: 0;
	}

	h2 {
		font-size: 12px;
		letter-spacing: 0.07em;
		color: $colorBright;
		margin-bottom: 40px;

		@include breakpoint(mobileMedium) {
			font-size: 10px;
			margin-bottom: 25px;
		}
	}

	h1 {
		font-size: $frontpageHeadlineSize;
		color: $colorDark;

		@include breakpoint(mobileMedium) {
			font-size: $frontpageHeadlineSizeMobile;
			margin-bottom: 25px;
		}
	}

	.btn {
		margin-top: 20px;

		@include breakpoint(mobileMedium) {
			margin-top: 0;	
		}
	}

	.quote {
		max-width: 450px;
		width: 100%;
		font-size: 18px;
		opacity: 0.8;

		@include breakpoint(mobileMedium) {
			font-size: 16px;
			margin-top: initial;
			width: 220px;
		}

		@include breakpoint(mobileExtraSmall) {
			font-size: 15px;
			width: 200px;
		}

		&:first-of-type {
			margin-top: auto;
			margin-bottom: 30px;

			@include breakpoint(mobileMedium) {
				margin-top: initial;
			}
		}

		.quote-originator {
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 0.05em;
		}
	}



}